// 1. import `extendTheme` function
import { ColorMode, extendTheme } from "@chakra-ui/react";
// 2. Add your color mode config
const config = {
	initialColorMode: "dark" as ColorMode,
	useSystemColorMode: false,
	textStyles: {
		stakingBoxHeader: {
			fontSize: "sm",
			marginTop: "4",
			color: "gray.400",
			fontStyle: "italic",
		},
		stakingBoxBody: {
			fontSize: "md",
			marginTop: "2",
			color: "gray.200",
			fontWeight: "bold",
		},
		stakingModalHeader: {
			fontSize: "lg",
			fontWeight: "bold",
			color: "gray.200",
			marginBottom: 2,
		},
		stakingModalBody: {
			fontSize: "sm",
			color: "gray.200",
		},
		questStatusHeader: {
			fontSize: "30px",
			fontStyle: "normal",
			fontWeight: "700",
			lineHeight: "1px",
			letterSpacing: "0em",
			textAlign: "center",
			marginLeft: "5px",
		},
		timeBlock: {
			background: "#19122C",
			paddingLeft: "2px",
			paddingRight: "2px",
			borderRadius: "2px",
		},
		statsHeader: {
			fontSize: "20px",
			fontStyle: "normal",
			fontWeight: "700",
			letterSpacing: "0em",
			textAlign: "left",
		},
	},
};
// 3. extend the theme
const theme = extendTheme(config);
export default theme;
