import type { AppContext, AppProps } from "next/app";
import App from "next/app"
import { CSSReset, ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import theme from "../util/theme";

function getLibrary(provider: any): Web3Provider {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12000;
	return library;
}

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<ChakraProvider theme={theme}>
			<CSSReset />
			<Web3ReactProvider getLibrary={getLibrary}>
				<ColorModeScript initialColorMode={theme.initialColorMode} />
				<Component {...pageProps} />
			</Web3ReactProvider>
		</ChakraProvider>
	);
}

MyApp.getInitialProps = async (context: AppContext) => {
	// See: https://nextjs.org/docs/advanced-features/custom-app
	const ctx = context.ctx
	if (ctx.req?.headers.host?.includes("econ.cryptoraiders.xyz")) {
		ctx.res!.writeHead(308, {
			Location: "https://econ.cryptoraiders.gg",
		})
		return { props: { } };
	}
	return await App.getInitialProps(context)
};

export default MyApp;
